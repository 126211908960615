<template>
  <div>
    <p class="text-3xl mb-6">Мои Данные</p>
    <v-row>
      <v-col cols="12" md="7">
        <bmi-chart></bmi-chart>
      </v-col>
      <v-col cols="12" md="5">
        <caloric-needs></caloric-needs>
      </v-col>
    </v-row>
    <p class="text-3xl mt-16 mb-6">Доступные программы</p>
    <available-marathons></available-marathons>
  </div>
</template>

<script>
import BmiChart from "./charts/BmiChart.vue";
import CaloricNeeds from "./charts/CaloricNeeds.vue";
import AvailableMarathons from "./AvailableMarathons.vue";
import store from "@/store";
import NoProductsPurchased from "./NoProductsPurchased.vue";

export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Home",
  },
  components: {
    BmiChart,
    CaloricNeeds,
    AvailableMarathons,
    NoProductsPurchased,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    firtName() {
      if (store.state.user.user.displayName !== "") {
        let firstName = store.state.user.user.displayName.split(" ");
        return firstName[0];
      }

      return store.state.user.user.email;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@core/preset/preset/misc.scss";
@import "~@core/preset/preset/mixins.scss";
.v-card {
  height: 100%;
}

.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 3px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(
    map-deep-get($material, "primary-shade"),
    map-deep-get($material, "states", "hover")
  );
}

.memberpricing-bg {
  position: relative;
}
.membership-pricing {
  text-align: center;
  height: 100%;
  sup {
    font-size: 3.75rem;
    top: 9px;
  }
}
</style>
